<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="formType === 'ADD' ? $t('promo.btn.add') : $t('promo.btn.edit')"
    class="px-5 py-3"
  >
    <page-loading :show="loading" />
    <form @submit.prevent="submit">
      <div class="form--group row" :class="{ 'has-error': validation.hasError('promoCode') }">
        <label class="col-12 col-lg-3" for="promoCode"
          >Kode Promo<span class="required">*</span></label
        >
        <div class="col-12 col-lg-6">
          <v-text-field
            id="title"
            v-model="promoCode"
            type="text"
            name="promoCode"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('promoCode') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('selectedSales') }">
        <label class="col-12 col-lg-3" for="selectedSales">Sales</label>
        <div class="col-12 col-lg-6">
          <multiselect
            name="selectedSales"
            id="selectedSales"
            v-model="selectedSales"
            :options="salesList"
            :searchable="true"
            :close-on-select="true"
            track-by="uuid"
            label="name"
          />
          <span class="val-error">{{ validation.firstError('selectedSales') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('qty') }">
        <label class="col-12 col-lg-3" for="qty">Kuantitas</label>
        <div class="col-12 col-lg-6">
          <v-text-field id="title" v-model="qty" type="number" name="qty" outlined hide-details />
          <span class="val-error">{{ validation.firstError('qty') }}</span>
        </div>
      </div>
      <div
        class="form--group row"
        :class="{ 'has-error': validation.hasError('discountPercentage') }"
      >
        <label class="col-12 col-lg-3" for="discountPercentage">Persentase Diskon (%)</label>
        <div class="col-12 col-lg-6">
          <cleave
            v-model="discountPercentage"
            :options="cleaveOptionDecimal"
            class="basic--input"
            name="discountPercentage"
            type="text"
            placeholder="Persentase Diskon (%)"
          />
          <span class="val-error">{{ validation.firstError('discountPercentage') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('discountAmount') }">
        <label class="col-12 col-lg-3" for="discountAmount">Jumlah Diskon</label>
        <div class="col-12 col-lg-6">
          <cleave
            v-model="discountAmount"
            :options="cleaveOptionCurrency"
            class="basic--input"
            name="discountAmount"
            type="text"
            placeholder="Jumlah Diskon"
          />
          <span class="val-error">{{ validation.firstError('discountAmount') }}</span>
        </div>
      </div>
      <div
        class="form--group row"
        :class="{ 'has-error': validation.hasError('purchaseLimitPerUser') }"
      >
        <label class="col-12 col-lg-3" for="purchaseLimitPerUser">Batas Pembelian Per User</label>
        <div class="col-12 col-lg-6">
          <v-text-field
            id="title"
            v-model="purchaseLimitPerUser"
            type="number"
            name="purchaseLimitPerUser"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('purchaseLimitPerUser') }}</span>
        </div>
      </div>
      <allowed-emails-section />
      <requirements-section ref="requirementSection" />
      <div class="form--group row">
        <label class="col-12 col-lg-3">Aktif?</label>
        <div class="col-12 col-lg-6">
          <v-btn
            type="button"
            color="success"
            :class="{ 'v-btn--disabled': !isActive }"
            @click="isActive = true"
          >
            {{ $t('general.yes') }}
          </v-btn>
          <v-btn
            type="button"
            color="error"
            :class="{ 'v-btn--disabled': isActive }"
            @click="isActive = false"
          >
            {{ $t('general.no') }}
          </v-btn>
        </div>
      </div>
      <div class="d-flex align-center justify-end">
        <v-btn color="primary" class="bottom--button" type="submit">
          {{ $t('promo.btn.save') }}
        </v-btn>
      </div>
    </form>
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const AllowedEmailsSection = () =>
  import('@/components/promo-management/promo/form/allowed-emails/allowed-emails-section');
const RequirementsSection = () =>
  import('@/components/promo-management/promo/form/requirements/requirements-section');
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import Multiselect from 'vue-multiselect';
require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  name: 'unit-type-form',
  mixins: [HelperMixin],
  components: { PageLoading, Multiselect, AllowedEmailsSection, RequirementsSection },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapState({
      cleaveOptionCurrency: (state) => state.global.cleaveOptionCurrency,
      cleaveOptionDecimal: (state) => state.global.cleaveOptionDecimal,
      formType: (state) => state.promo.form.formType,
      salesList: (state) => state.promo.form.salesList,
      membershipTypes: (state) => state.promo.form.membershipTypes,
      productTypes: (state) => state.promo.form.productTypes,
    }),
    loading: {
      get() {
        return this.$store.state.promo.form.loading;
      },
      set(value) {
        this.$store.commit('promo/form/SET_LOADING', value);
      },
    },
    promoCode: {
      get() {
        return this.$store.state.promo.form.promoCode;
      },
      set(value) {
        this.$store.commit('promo/form/SET_PROMO_CODE', value);
      },
    },
    selectedSales: {
      get() {
        return this.$store.state.promo.form.selectedSales;
      },
      set(value) {
        this.$store.commit('promo/form/SET_SELECTED_SALES', value);
      },
    },
    qty: {
      get() {
        return this.$store.state.promo.form.qty;
      },
      set(value) {
        this.$store.commit('promo/form/SET_QTY', value);
      },
    },
    isActive: {
      get() {
        return this.$store.state.promo.form.isActive;
      },
      set(value) {
        this.$store.commit('promo/form/SET_IS_ACTIVE', value);
      },
    },
    discountPercentage: {
      get() {
        return this.$store.state.promo.form.discountPercentage;
      },
      set(value) {
        this.$store.commit('promo/form/SET_DISCOUNT_PERCENTAGE', value);
      },
    },
    discountAmount: {
      get() {
        return this.$store.state.promo.form.discountAmount;
      },
      set(value) {
        this.$store.commit('promo/form/SET_DISCOUNT_AMOUNT', value);
      },
    },
    purchaseLimitPerUser: {
      get() {
        return this.$store.state.promo.form.purchaseLimitPerUser;
      },
      set(value) {
        this.$store.commit('promo/form/SET_PURCHASE_LIMIT_PER_USER', value);
      },
    },
  },

  validators: {
    promoCode(value) {
      return Validator.value(value).required(this.$i18n.t('errors.promo.promoCode.required'));
    },
  },
  methods: {
    completed() {
      this.loading = false;
    },
    validate() {
      let validationArray = [];
      validationArray.push(this.$refs.requirementSection.validate());
      validationArray.push(this.$validate());
      return Promise.all(validationArray).then(function (results) {
        if (
          results.filter(function (result) {
            console.log('WHICH SECTION FAILED: ', result);
            return !result;
          }).length === 0
        ) {
          console.log('ALL VALID!');
          return true;
        }
        console.log('SOME INVALID!');
        return false;
      });
    },
    async submit() {
      if (await this.validate()) {
        await this.$store.dispatch('promo/form/postData');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t(this.formType === 'ADD' ? 'promo.add.successMsg' : 'promo.edit.successMsg'),
          'success',
        );
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>

<style scoped></style>
